/* eslint-disable no-magic-numbers */
import { datadogRum } from '@datadog/browser-rum'
import { captureException, withScope } from '@sentry/browser'
import { navigate } from 'gatsby'
import toast from 'react-hot-toast'

import { createReferralPartner } from '../../../api/partner.service'
import { createZendeskRequest } from '../../../api/zendesk.service'
import { IPartnerObjectValues, IProductConfigExtension, trimObjValues } from '../../../utils/trim'
import { IGetOnSubmitFormFnValues, IProductSelection, IRestValues } from './../registerForm'
import mapToZendeskPayload from './mapToZendeskPayload'

export const fillProductConfigExtension = (
  productConfigExtension: IProductConfigExtension,
  productSelection: IProductSelection
): IProductConfigExtension =>
  Object.entries(productSelection).reduce((acc, [productId, status]) => {
    const isContainsExtension =
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      Object.keys(productConfigExtension?.[productId]?.values || {}).length > 0
    if (status === true && isContainsExtension) {
      return {
        ...acc,
        [productId]: productConfigExtension[productId],
      }
    }
    return acc
  }, {})

export const getOnSubmitFormFn =
  (...rest: IRestValues) =>
  async (values: IGetOnSubmitFormFnValues): Promise<void> => {
    const [country, redirectSuccessLink, redirectFailLink, productSelectionOptions] = rest

    const productSelectionIds = productSelectionOptions
      .filter((item) => values.productSelection[item.key] === true)
      .map((item) => item.key)
      .join(',')

    const partnerRequest: IPartnerObjectValues = {
      companyName: values.firmName,
      legalRepFirstName: values.legalRepFirstName,
      legalRepLastName: values.legalRepLastName,
      legalEntity: values.legalEntity,
      salesTaxIdNumber: values.salesTaxIdNumber,
      companyAddressCity: values.firmAddressCity,
      companyAddressHouseNumber: values.firmAddressHouse,
      companyAddressPlz: values.firmAddressPlz,
      companyAddressStreet: values.firmAddressStreet,
      companyEmail: values.firmEmailId,
      productSelectionIds,
      productConfigExtension: fillProductConfigExtension(
        values.productConfigExtension,
        values.productSelection
      ),
      subdomain: values.desiredURL,
      source: values.source,
      sourceReferral: values.sourceReferral,
      country,
    }

    if (values.firmRegistrationNumber !== '') {
      partnerRequest.companyRegistrationNumber = values.firmRegistrationNumber
    }

    if (values.issuingAuthority !== '') {
      partnerRequest.issuingAuthority = values.issuingAuthority
    }

    if (values.firmTelephoneNumber !== '') {
      partnerRequest.companyPhone = values.firmTelephoneNumber
    }

    if (values.billingIban !== '') {
      partnerRequest.iban = values.billingIban
    }

    if (values.billingEmail !== '') {
      partnerRequest.billingEmail = values.billingEmail
    }

    const formattedPartnerReq = trimObjValues(partnerRequest)

    try {
      await createReferralPartner(formattedPartnerReq)
    } catch (error) {
      withScope((scope) => {
        if (typeof window !== 'undefined') {
          scope.setExtras({ url: window.location.href })
        }
        captureException(error)
      })
      datadogRum.addError(error)
      if (typeof window !== 'undefined') {
        if (error.response && error.response.status >= 400 && error.response.status < 500) {
          toast.error(`Error: ${error.response?.data?.message || 'An error occurred'}`)
        }
        return
      }
      void navigate(redirectFailLink)
      return
    }

    try {
      await createZendeskRequest(mapToZendeskPayload(formattedPartnerReq))
    } catch (error) {
      withScope((scope) => {
        if (typeof window !== 'undefined') {
          scope.setExtras({ url: window.location.href })
        }
        captureException(error)
      })
      datadogRum.addError(error)
    }

    void navigate(`${redirectSuccessLink}/?verified=true&form=register-${country.toLowerCase()}`)
  }
